import * as React from "react";
import makeStyles from '@mui/styles/makeStyles';

import { Box, IconButton } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import copy from 'copy-to-clipboard';


const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: '#F8F9FA',
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      padding: theme.spacing(2),
      width: '100%'
    },
    code: {
      fontFamily: "monospace",
      flexGrow: 1,
      wordBreak: "break-word",
    },
  }
});


const CopyCodeSnippet = ({text}) => {
  const [copied, setCopied] = React.useState(false);
  const classes = useStyles();

  const handleCopy = (event) => {
    setCopied(true);
    return copy(text);
  } 

  return (
    <Box className={classes.root}>
      <div className={classes.code}>
        {text}
      </div>
      <IconButton onClick={handleCopy} color={copied ? "success" : "info"} aria-label="copy to clipboard">
        <ContentCopyIcon />
      </IconButton>
    </Box>
  )
}

export default CopyCodeSnippet;
