export const stripeCustomer = (data) => ({
    // only include fields that the Stripe API can consume
    "name": `${data.first_name} ${data.last_name}`,
    "email": data.email,
})

export const stripeAddress = (data) => ({
    "country": data.country,
    "line1": data.line1,
    "line2": data.line2,
    "city": data.city,
    "state": data.state,
    "postal_code": data.postal_code,
})