// from https://github.com/mui/material-ui/issues/16037

import React from 'react';
import {
  AddressElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { TextField, TextFieldProps } from "@mui/material";
import StripeInput from './StripeInput';

export type StripeElement =
  | typeof AddressElement
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement;

type StripeTextFieldProps<T extends StripeElement> = Omit<
  TextFieldProps,
  'onChange' | 'inputComponent' | 'inputProps'
> & {
  inputProps?: React.ComponentProps<T>;
  labelErrorMessage?: string;
  onChange?: React.ComponentProps<T>['onChange'];
  stripeElement?: T;
  mode?: string;
};

const StripeTextDefaultProps = {
  inputProps: {},
  labelErrorMessage: '',
  onChange: () => {},
  stripeElement: null,
  mode: ''
};

export const StripeTextField = <T extends StripeElement>(
  props: StripeTextFieldProps<T>
): JSX.Element => {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    ...other
  } = props;

  return (
    <TextField
      fullWidth
      variant="filled"
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      error={error}
      InputProps={{
        ...InputProps,
        // @ts-ignore
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
        },
        // @ts-ignore
        inputComponent: StripeInput,
      }}
      helperText={error ? labelErrorMessage : helperText}
      {...other}
    />
  );
};

StripeTextField.defaultProps = StripeTextDefaultProps;

export function StripeTextFieldNumber(
  props: StripeTextFieldProps<typeof CardNumberElement>
): JSX.Element {
  return (
    <StripeTextField
      {...props}
      label="Card number"
      inputProps={{options: {placeholder: props.placeholder}}}
      stripeElement={CardNumberElement}
    />
  );
}

StripeTextFieldNumber.defaultProps = StripeTextDefaultProps;

export function StripeTextFieldExpiry(
  props: StripeTextFieldProps<typeof CardExpiryElement>
): JSX.Element {
  return (
    <StripeTextField
      {...props}
      label="Expiration date"
      inputProps={{options: {placeholder: props.placeholder}}}
      stripeElement={CardExpiryElement}
    />
  );
}

StripeTextFieldExpiry.defaultProps = StripeTextDefaultProps;

export function StripeTextFieldCVC(
  props: StripeTextFieldProps<typeof CardCvcElement>
): JSX.Element {
  return (
    <StripeTextField
      {...props}
      label="CVC"
      inputProps={{options: {placeholder: props.placeholder}}}
      stripeElement={CardCvcElement}
    />
  );
}

StripeTextFieldCVC.defaultProps = StripeTextDefaultProps;

export function StripeTextFieldBillingAddress(
  props: StripeTextFieldProps<typeof AddressElement>
): JSX.Element {
  return (
    <StripeTextField
      {...props}
      inputProps={{options: {mode: 'billing'}}}
      stripeElement={AddressElement}
    />
  );
}

StripeTextFieldBillingAddress.defaultProps = StripeTextDefaultProps;
