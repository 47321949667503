import * as React from "react";

import {
    Alert,
    AlertTitle,
    List,
    ListItem,
    Typography,
    Divider,
  } from "@mui/material";
import LocalOffer from "@mui/icons-material/LocalOffer";

import makeStyles from '@mui/styles/makeStyles';

import { ISODateToLocaleString, USDFormatter } from "../../utils";
import { useIOProducts } from "../../hooks/use-io-products";

const useStyles = makeStyles((theme) => ({
    order: {
      '& li.MuiListItem-root': {
        justifyContent: "space-between",
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0)
      }
    },
    orderEmphasis: {
      fontWeight: 700
    },
    orderLighten: {
      color: theme.palette.grey["700"],
    },
    orderTotal: {
      fontSize: theme.typography.fontSize,
      fontWeight: 700
    },
  }));

export default function OrderSummary(props) {
    const { order, invoice } = props;
  
    const classes = useStyles();
    const { IO_PRODUCT_LOOKUP } = useIOProducts();
    
    return (
      <>
        <Divider />
        <Alert icon={false} severity="success" color="info" sx={{mt: 1, mb: 2}}>
          <AlertTitle sx={{fontWeight: "bold"}}>{order.aoi_label}</AlertTitle>
          <Typography variant="body2">{IO_PRODUCT_LOOKUP[order.product_id].title}</Typography>
          <Typography variant="body2">{ISODateToLocaleString(order.start_date)} - {ISODateToLocaleString(order.end_date)}</Typography>
        </Alert>
        <Divider />
        <List className={classes.order}>
          <ListItem key="map-extent">
            <Typography variant="overline">Map Extent</Typography>
            <Typography variant="overline" className={classes.orderEmphasis}>
              {order.area?.toLocaleString()} km2
            </Typography>
          </ListItem>
  
          { (invoice?.total_discount_amounts?.length > 0) && (
            <>
               { (invoice?.subtotal > 0) && (
                    <ListItem key="original-price">
                        <Typography variant="overline">Original Price</Typography>
                        <Typography variant="overline" className={classes.orderLighten}>{USDFormatter.format(invoice.subtotal / 100)}</Typography>
                    </ListItem>
              )}
              {invoice.total_discount_amounts.map((discount) => {
                if (discount['amount'] > 0) {
                  return (
                  <ListItem key="discount">
                    <Typography variant="overline">Discount <LocalOffer sx={{width: 16, verticalAlign: 'middle', color: "rgba(0, 0, 0, 0.54)"}}/></Typography>
                    <Typography variant="overline">- {USDFormatter.format(discount['amount'] / 100 )}</Typography>
                  </ListItem>
                  )
                } else {
                    return <></>
                }
              })}
            </>
          )}
  
          { (invoice?.tax > 0) && (
            <ListItem key="tax">
              <Typography variant="overline">Tax</Typography>
              <Typography variant="overline">{USDFormatter.format(invoice.tax / 100 )}</Typography>
            </ListItem>
          )}
  
          { (invoice?.subtotal > 0) && (
            <Divider />
          )}

          { invoice && (<>
            <ListItem key="price">
              <Typography variant="overline">Price</Typography>
              <Typography variant="overline" className={classes.orderTotal} >
                  {(invoice.total > 0) ? USDFormatter.format(invoice.total / 100) : "FREE"}
                </Typography>              
            </ListItem>
          </>)}
  
        </List>
      </>
    )
  }