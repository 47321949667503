import * as React from "react";

import {
  Typography,
  Grid,
  Container,
} from "@mui/material";

export default function OrderError(props) {
	return (
    <Container>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={9}>
          <Typography variant="h4" component="h2" textAlign="center">
            Order Error
          </Typography>
          <Typography variant="body1">
            {props.message}
          </Typography>
        </Grid>
      </Grid>
    </Container>
	)
}