import * as React from "react";

import makeStyles from '@mui/styles/makeStyles';

import { Link, navigate } from "gatsby";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

import Feedback from './Feedback';
import OrderError from "./OrderError";

const MOD_API_URL = process.env.GATSBY_MOD_API_URL;

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: theme.palette.grey["100"],
    },
  }
});

export default function ProcessingOrder(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState({});
  const [loadError, setLoadError] = React.useState(false);

  React.useEffect( loadOrder => {
    if (window.history.state?.order) {
      setOrder(window.history.state.order);
    } else {
      fetch(`${MOD_API_URL}/order/${props.id}/details`)
        .then(async(response) => {
          if(response.ok) {
            return await response.json()
          } else {
              throw new Error("unable to get order")
          }
        })
        .then(resultData => {
          setOrder(resultData);
        })
        .catch(e => {
          console.error(e)
          setLoadError(`Sorry, we are unable to process order #${props.id}`)
        })
    }
  }, [props.id])

  React.useEffect( redirectFinishedOrder => {
    if (order.status && (order.status === "DONE" || order.status === "succeeded")) {
      navigate(`/order/${order.id}/download`, {state: {disableScrollUpdate: true}})
    }
  }, [order])

  if (loadError) {
    return (
      <OrderError message={loadError}/>
    )
  }

  return (
    order.status ? (
      <div className="processingOrder">
        <Container>
          <Grid container spacing={3} justifyContent="center">
            <Grid item lg={9}>
              <Typography variant="h4" component="h2" textAlign="center">
                Thank You for Your Order
              </Typography>
              <Typography variant="body2" textAlign="left">
                
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Box className={classes.root}>
          <Container>
            <Grid container spacing={3} justifyContent="center">
              <Grid container item xs={12} flexDirection={"column"} alignItems={"center"}>
                <CheckCircleOutline fontSize="large" color={"success"} />
                <Typography variant="h6" fontWeight={"normal"} >Success!</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box textAlign="center">
                  <Typography>Order <strong>{order.id}</strong> is being processed.</Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="body1">You have successfully requested a custom map from the IO Monitor and should receive a confirmation email shortly at <strong>{order.email}</strong>. Once we have processed your map, you will receive an email with download instructions.</Typography>
                </Box>
                <Box mt={2}>
                    <Feedback container={false} showRating={true} order={order} />
                </Box>
                <Box mt={2}>
                  <Typography variant="body2" mt={1}><em>For any questions or concerns, contact us at <a href="mailto:store@impactobservatory.com" target="_blank" rel="noreferrer">store@impactobservatory.com</a></em></Typography>
                </Box>
              </Grid>
              {/* <Grid container item xs={12} flexDirection={"row"} justifyContent={"center"}>
                <Button variant="outlined" sx={{mr: 1}}><a href="https://www.impactobservatory.com/monitoring_on_demand">IO Monitoring</a></Button>
                <Button variant="contained"><Link to="/order/">Create New Map</Link></Button>
              </Grid> */}
            </Grid>
          </Container>
        </Box>
    </div>
  ) : (
    loadError ? (
      <OrderError message={loadError}/>
    ) : (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    )
  )
  );
}
