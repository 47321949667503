import * as React from 'react';

import { Autocomplete, Grid, TextField } from "@mui/material";
import { CountryRegionData } from "react-country-region-selector";

import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";

import { isAlpha } from "../../utils";

const REGION_LABEL = {
  "US": "State",
  "GB": "County",
  "AU": "State or Territory",
  "CA": "Province or Territory",
  "NZ": "Region",
  "IN": "State",
  "DE": "State",
  "NL": "Province",
  "ES": "Province",
  "SG": "District",
  "RO": "County",
  "FR": "Region",
  "IE": "County",
  "UA": "Obalast"
};

export default function AddressDetail(props) {
  const { formMethods } = props;
  const { control, formState: { errors }} = formMethods;

  const [regions, setRegions] = React.useState([]);
  const [regionLabel, setRegionLabel] = React.useState();

  const updateRegions = (countryArray) => {
    // countryArray like ["United States", "US", "Alabamba~AL|Alaska~AK|..."]
    if (!countryArray) {
      return false;
    }
    const availableRegions = countryArray[2].split("|").map(regionPair => {
      let [regionName, regionShortCode = null] = regionPair.split("~");
      return [regionName, regionShortCode];
    });
    setRegions(availableRegions);
    setRegionLabel(REGION_LABEL[countryArray[1]] || 'Region');
  }

  return (
    <>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Controller
            name="address.country"
            control={control}
            rules={{ required: true }}
            render = {({ field: { onChange } })=> (
              <Autocomplete
                sx={{ minWidth: 250 }}
                options={CountryRegionData}
                autoHighlight
                getOptionLabel={(option) => option[0] || ""}
                onChange={(event, item) => {
                  if (item) {
                    // update regions
                    updateRegions(item);
                    // store country ISO code to form state
                    onChange(item[1]);
                  } else {
                    onChange(null);
                    setRegionLabel('Region');
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    variant="filled"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    error={!!errors.address?.country}
                    helperText={errors.address?.country?.message}
                  />
                )}
              />
            )}
          />
        </FormControl>
        
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <Controller
            name="address.line1"
            control={control}
            rules={{ required: true }}
            render = {({ field })=> (
              <TextField
                  {...field}
                  error={!!errors.address?.line1}
                  helperText={errors.address?.line1?.message}
                  label="Address"
                  variant="filled"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Controller
            name="address.line2"
            control={control}
            rules={{ required: true }}
            render = {({ field })=> (
              <TextField
                  {...field}
                  error={!!errors.address?.line2}
                  helperText={errors.address?.line2?.message}
                  label="Apt, suite, etc"
                  variant="filled"
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={7}>
        <FormControl fullWidth>
          <Controller
            name="address.city"
            control={control}
            rules={{ required: true }}
            render = {({ field })=> (
              <TextField
                  {...field}
                  error={!!errors.address?.city}
                  helperText={errors.address?.city?.message}
                  label="City"
                  variant="filled"
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={5}>
        <FormControl fullWidth>
          <Controller
            name="address.state"
            control={control}
            rules={{ required: true }}
            render = {({ field: { onChange } })=> (
              <Autocomplete
                id="region-select"
                options={regions}
                autoHighlight
                isOptionEqualToValue={(option, value) => (option[1] === value)}
                getOptionLabel={(option) => {
                  if (option) {
                    return isAlpha(option[1]) ? option[1] : option[0] || option}
                }}
                onChange={(event, item) => {
                  if (item) {
                    onChange(isAlpha(item[1]) ? item[1] : item[0] || item);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={regionLabel}
                    variant="filled"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <Controller
            name="address.postal_code"
            control={control}
            rules={{ required: true }}
            render = {({ field })=> (
              <TextField
                  {...field}
                  error={!!errors.address?.postal_code}
                  helperText={errors.address?.postal_code?.message}
                  label="Postcode"
                  variant="filled"
              />
            )}
          />
        </FormControl>
      </Grid>
    </>
  );
}