// from https://github.com/mui/material-ui/issues/16037

import * as React from 'react';

import { Grid, FormHelperText } from "@mui/material";
import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCVC,
} from './commonTextFields';

export default function CreditCardDetail(props): JSX.Element {
  const [state, setState] = React.useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    addressComplete: false,
    cardNumberError: undefined,
    expiredError: undefined,
    cvcError: undefined,
  });

  const onElementChange = (field: string, errorField: string) => ({
    complete,
    error = { message: null },
  }: {
    complete: boolean;
    error?: { message: null | string };
  }) => {
    setState({ ...state, [field]: complete, [errorField]: error.message });
  };

  const { cardNumberError, expiredError, cvcError } = state;
  const { paymentError } = props;

  return (
    <>
      <Grid item xs={12}>
        <StripeTextFieldNumber
          error={Boolean(cardNumberError)}
          labelErrorMessage={cardNumberError}
          placeholder={''} // default 1234 1234 1234 1234
          onChange={onElementChange('cardNumberComplete', 'cardNumberError')}
        />
      </Grid>

      <Grid item xs={8}>
        <StripeTextFieldExpiry
          error={Boolean(expiredError)}
          labelErrorMessage={expiredError}
          // placeholder={''} // default MM / YY
          onChange={onElementChange('expiredComplete', 'expiredError')}
        />
      </Grid>
      <Grid item xs={4}>
        <StripeTextFieldCVC
          error={Boolean(cvcError)}
          labelErrorMessage={cvcError}
          placeholder={''} // default CVC
          onChange={onElementChange('cvcComplete', 'cvcError')}
        />
      </Grid>
      {paymentError && (
        <FormHelperText error={true} sx={{ml: 1}}>{paymentError}</FormHelperText>
      )}
    </>
  );
}