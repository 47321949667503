import React from "react"
import { Router } from "@reach/router"
import Layout from "../../sections/Layout"
import Seo from "../../sections/SEO";

import ReviewOrder from "../../components/order/ReviewOrder"
import ProcessingOrder from "../../components/order/ProcessingOrder"
import DownloadOrder from "../../components/order/DownloadOrder"
import SimplePage from "../../components/SimplePage"

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";

const STRIPE_API_KEY = process.env.GATSBY_STRIPE_API_KEY;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_API_KEY);



const OrderRouter = () => {
  return (
    <Elements stripe={stripePromise}>
      <Layout title="Monitor">
        <Router basepath="/order">
          <ReviewOrder path="/:id/" />
          <ProcessingOrder path="/:id/processing" />
          <DownloadOrder path="/:id/download" />
          <SimplePage default title="Loading" body={"Please wait while we load your order"} />
        </Router>
      </Layout>
    </Elements>
  )
}

export default OrderRouter;

export const Head = () => (
  <Seo />
)