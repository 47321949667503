import * as React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  return {
    download: {
      padding: '0 4px',
    },
  }
});

function DownloadLink({ href, filename, children }) {
  const classes = useStyles();
 
  return (
    <a href={href} download={filename} className={classes.download}>
      <Typography mb={0} component={"span"}>{children}</Typography>
    </a>
  );
}

export default DownloadLink;
